<template>
  <Item
    :item="item"
    :parent="parent"
    class="flex"
    :class="{
      'justify-start': parentAlign === 'left',
      'justify-center': parentAlign === 'center',
      'justify-end': parentAlign === 'right',
    }"
  >
    <ul
      class="flex gap-x-6 gap-y-4 rich-list"
      :class="{
        'justify-start': parentAlign === 'left',
        'justify-center': parentAlign === 'center',
        'justify-end': parentAlign === 'right',
        'flex-row flex-wrap': displayInline,
        'flex-col': !displayInline,
      }"
    >
      <li
        v-for="({ text, url }, idx) in item.settings.items"
        :key="idx"
        class="flex items-start"
        :class="{
          'rounded-lg': btnShape === 'rounded',
          'rounded-full': btnShape === 'roundedFull',
          'rich-list--button-outlined': appearance === 'outlinedButton',
          'rich-list--button-filled': appearance === 'filledButton',
        }"
      >
        <div class="flex items-center">
          &#8203;
          <component
            :is="bulletIcon"
            v-if="bulletIcon"
            class="-mt-px shrink-0 rich-list__icon"
            :class="{
              'w-5 h-5': ['sm', 'md'].includes(parentFontSize),
              'w-6 h-6': ['lg', 'xl'].includes(parentFontSize),
              'w-7 h-7': ['xl'].includes(parentFontSize),
            }"
          />
        </div>

        <component
          :is="url ? 'a' : 'span'"
          class="ml-2"
          v-bind="
            url
              ? {
                  href: isAdminMode ? '#' : url,
                  rel: 'noreferrer noopener',
                }
              : {}
          "
        >
          {{ text }}
        </component>
      </li>
    </ul>
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'RichList',

  components: {
    ArrowIcon: () => import('~/assets/icons-public/arrow-right.svg?inline'),
    CheckboxIcon: () =>
      import('~/assets/icons-public/checkbox-rounded-filled.svg?inline'),
    StarIcon: () => import('~/assets/icons-public/star-filled.svg?inline'),
    HeartIcon: () => import('~/assets/icons-public/heart-filled.svg?inline'),
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  computed: {
    isAdminMode: get('isAdminMode'),
    btnShape: get('page/btnShape'),

    displayInline() {
      return this.item.settings.opts?.inline ?? false
    },

    bulletType() {
      return this.item.settings.opts?.bulletType ?? 'arrow'
    },

    appearance() {
      return this.item.settings.opts?.appearance ?? 'text'
    },

    bulletIcon() {
      return {
        checkbox: 'CheckboxIcon',
        heart: 'HeartIcon',
        star: 'StarIcon',
        arrow: 'ArrowIcon',
      }[this.bulletType]
    },

    parentFontSize() {
      return this.parent.props.fontSize
    },

    parentAlign() {
      return this.parent.props.align
    },
  },

  methods: {
    getHostName(url) {
      return new URL(url).hostname
    },
  },
}
</script>

<style lang="postcss">
.rich-list {
  a {
    color: var(--contrastColor);
  }

  span {
    color: var(--textColor);
  }

  .rich-list__arrow {
    color: var(--contrastColor);
  }

  .rich-list__icon {
    > * {
      fill: var(--contrastColor);
    }
  }
}

.rich-list--button-filled {
  background-color: var(--contrastColor);
  @apply py-3 px-5 font-bold;

  a {
    color: var(--contrastOverlayColor) !important;
  }

  span {
    color: var(--contrastOverlayColor);
  }

  .rich-list__arrow {
    color: var(--contrastOverlayColor);
  }

  .rich-list__icon {
    > * {
      fill: var(--contrastOverlayColor);
    }
  }
}

.rich-list--button-outlined {
  border-color: var(--contrastColor);

  @apply border-2 py-3 px-5 font-bold;

  span {
    color: var(--contrastColor);
  }
}
</style>
