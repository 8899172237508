var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{staticClass:"flex",class:{
    'justify-start': _vm.parentAlign === 'left',
    'justify-center': _vm.parentAlign === 'center',
    'justify-end': _vm.parentAlign === 'right',
  },attrs:{"item":_vm.item,"parent":_vm.parent}},[_c('ul',{staticClass:"flex gap-x-6 gap-y-4 rich-list",class:{
      'justify-start': _vm.parentAlign === 'left',
      'justify-center': _vm.parentAlign === 'center',
      'justify-end': _vm.parentAlign === 'right',
      'flex-row flex-wrap': _vm.displayInline,
      'flex-col': !_vm.displayInline,
    }},_vm._l((_vm.item.settings.items),function(ref,idx){
    var text = ref.text;
    var url = ref.url;
return _c('li',{key:idx,staticClass:"flex items-start",class:{
        'rounded-lg': _vm.btnShape === 'rounded',
        'rounded-full': _vm.btnShape === 'roundedFull',
        'rich-list--button-outlined': _vm.appearance === 'outlinedButton',
        'rich-list--button-filled': _vm.appearance === 'filledButton',
      }},[_c('div',{staticClass:"flex items-center"},[_vm._v("\n        ​\n        "),(_vm.bulletIcon)?_c(_vm.bulletIcon,{tag:"component",staticClass:"-mt-px shrink-0 rich-list__icon",class:{
            'w-5 h-5': ['sm', 'md'].includes(_vm.parentFontSize),
            'w-6 h-6': ['lg', 'xl'].includes(_vm.parentFontSize),
            'w-7 h-7': ['xl'].includes(_vm.parentFontSize),
          }}):_vm._e()],1),_vm._v(" "),_c(url ? 'a' : 'span',_vm._b({tag:"component",staticClass:"ml-2"},'component',
          url
            ? {
                href: _vm.isAdminMode ? '#' : url,
                rel: 'noreferrer noopener',
              }
            : {}
        ,false),[_vm._v("\n        "+_vm._s(text)+"\n      ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }